// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'
import AboutTeaser from './../AboutTeaser'
import AboutIntro from './../AboutIntro'
import ClientOverview from '../../Clients/ClientOverview'

import messages from './WhyAgilePage.lang'
import { StaticImage } from 'gatsby-plugin-image'

const introImage = (
  <StaticImage src="../../../images/pictures/whyagile_intro_image.jpg" alt="" objectFit="cover" className="image" />
)

const WhyAgilePage = () => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <AboutTeaser title={formatMessage(messages.pageTitle)} />
      <AboutIntro
        title={formatMessage(messages.introTitle)}
        copy={<FormattedMessageSanitized {...messages.introInfo} />}
        image={introImage}
      />
      <ClientOverview />
    </div>
  )
}

export default WhyAgilePage
