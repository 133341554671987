// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import IntroTeaser from '../../IntroTeaser'

import baseStyles from './AboutIntro.scss'

type Props = {
  ...StyleProps
}

const AboutIntro = ({
  styles, ...rest
}: Props) => (
  // $FlowFixMe[cannot-spread-inexact]
  <IntroTeaser
    styles={styles.root}
    fullWidth
    {...rest}
  />
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(AboutIntro)
