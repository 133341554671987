// @flow

import React from 'react'
import SubpageHeroTeaser from '../../../components/SubpageHeroTeaser'
import { StaticImage } from 'gatsby-plugin-image'

const HeroTeaserImage = <StaticImage
  src='../../../images/heroteaser/about_heroteaser_bg_desktop.jpg'
  alt=''
  objectFit='cover'
  className='image'
/>

type Props = {}

const TechnologiesPage = (props: Props) => (
  // $FlowFixMe[cannot-spread-inexact]
  <SubpageHeroTeaser
    image={HeroTeaserImage}
    imgAlt='office gallery'
    {...props}
  />
)

export default TechnologiesPage
